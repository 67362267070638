import React from 'react'
import { useState } from 'react'
import { useThemeContext } from '../../context/DataContext'

export default function Statistique() {
  const { findElementByKey } = useThemeContext()
  const [showText1, setShowText1] = useState<boolean>(false)
  const [showText2, setShowText2] = useState<boolean>(false)
  const [showText3, setShowText3] = useState<boolean>(false)
  const [showText4, setShowText4] = useState<boolean>(false)

  return (
    <section
      className="wrapper bg-light"
      id="nav_statistiques"
    >
      <div className="container-card">
        <div className="card image-wrapper bg-full bg-dark">
          <div className="card-body pt-10 pb-14 " id='stats-card'>
            <h1 className="title text--dark text-center text-uppercase mb-5">
              {findElementByKey('stats_title_text')}
            </h1>
            <div className="container p-0">
              <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0">
                <div className="col-lg-4 text-center text-lg-start">
                  <h3 className="display-4 mb-3 text-white pe-xxl-15">
                    {findElementByKey('stats_title')}
                  </h3>
                  <p className="lead mb-0 text-secondary pe-xxl-10 text-justify ml-sm-5 caption">
                    {findElementByKey('stats_caption')}
                  </p>
                </div>
                <div className="col-lg-8 mt-lg-2">
                  <div className="row align-items-center counter-wrapper gy-6 text-center" id='gy-6'>
                    <div className="col-md-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="white"
                        className="bi bi-people-fill svg-inject icon-svg icon-svg-sm solid-duo text-grape-fuchsia mb-3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        <path
                          fillRule="evenodd"
                          d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                        />
                        <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                      </svg>
                      <h5 className="counter text-white">
                        {findElementByKey('stats_user_number')}
                      </h5>
                      <p className="mb-0 text-white text-center">
                        {findElementByKey('stats_user_text')}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="white"
                        className="bi bi-journal-check svg-inject icon-svg icon-svg-sm solid-duo text-grape-fuchsia mb-3"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                        />
                        <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                        <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                      </svg>
                      <h5 className="counter text-white">
                        {findElementByKey('stats_book')}
                      </h5>
                      <p className="mb-0 text-white text-center">
                        {findElementByKey('stats_book_text')}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="white"
                        className="bi bi-emoji-smile-fill svg-inject icon-svg icon-svg-sm solid-duo text-grape-fuchsia mb-3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z" />
                      </svg>
                      <h5 className="counter text-white">
                        {findElementByKey('stats_client')}
                      </h5>
                      <p className="mb-0 text-white text-center">
                        {findElementByKey('stats_client_text')}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="white"
                        className="bi bi-car-front-fill svg-inject icon-svg icon-svg-sm solid-duo text-grape-fuchsia mb-3"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189l.956-1.913A.5.5 0 0 1 4.309 3h7.382a.5.5 0 0 1 .447.276l.956 1.913a.51.51 0 0 1-.497.731c-.91-.073-3.35-.17-4.597-.17-1.247 0-3.688.097-4.597.17a.51.51 0 0 1-.497-.731Z"
                        />
                      </svg>
                      <h5 className="counter text-white">
                        {findElementByKey('stats_vehicle')}
                      </h5>
                      <p className="mb-0 text-white text-center">
                        {findElementByKey('stats_vehicle_text')}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="white"
                        className="bi bi-car-front svg-inject icon-svg icon-svg-sm solid-duo text-grape-fuchsia mb-3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z" />
                        <path
                          fillRule="evenodd"
                          d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z"
                        />
                      </svg>
                      <h5 className="counter text-white">
                        {findElementByKey('stats_available_car')}
                      </h5>
                      <p className="mb-0 text-white text-center">
                        {findElementByKey('stats_available_car_text')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
