import * as React from 'react'
import { useThemeContext } from '../../context/DataContext'
import { Link } from 'gatsby'
import { useEffect, useState } from 'react'


const NavBar: React.FC = () => {
  const { findElementByKey, currentLanguage, setLanguage } = useThemeContext()
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <header className="wrapper">
      <nav
        style={{ position: 'fixed', background: '#f1f1f1', padding: "0 5%" }}
        className="navbar navbar-expand-lg classic transparent navbar-light"
        id='nav'
      >
        <div className="container flex-lg-row flex-nowrap align-items-center" id='container-nav'>
          <div className="navbar-brand w-100">
            <a href="#nav_accueil">
              <img className='img-fluid' src="/img/LogoBlackNew.png" alt="logo" loading="lazy"/>
            </a>
          </div>
          <div
            tabIndex={-1}
            className={`navbar-collapse offcanvas-nav ${isOpen ? 'open' : ''}`}
            id='offcanvas-nav'
          >
            <div className="offcanvas-header d-lg-none d-xl-none" id='vertical-nav'>
              <a href="#nav_accueil">
                <img className='img-fluid' src="/img/LogoWhite.png" alt="logo" loading="lazy"/>
              </a>
              <button
                type="button"
                id='close'
                onClick={() => setIsOpen(!isOpen)}
                className="btn-close btn-close-white offcanvas-close offcanvas-nav-close"
                aria-label="Close"
              ></button>
            </div>
            <ul className="navbar-nav">
              <li
                className="nav-item"
                id="accueil"
              >
                <a
                  className="nav-link scroll active"
                  href="#nav_accueil"
                >
                  {findElementByKey('nav_accueil')}
                </a>
              </li>
              <li
                className="nav-item"
                id="estimation"
              >
                <a
                  className="nav-link scroll"
                  href="#nav_estimation"
                >
                  {findElementByKey('nav_estimation')}
                </a>
              </li>
              <li
                className="nav-item"
                id="service"
              >
                <a
                  className="nav-link scroll"
                  href="#nav_service"
                >
                  {findElementByKey('nav_service')}
                </a>
              </li>
              <li
                className="nav-item"
                id="statistique"
              >
                <a
                  className="nav-link scroll"
                  href="#nav_statistiques"
                >
                  {findElementByKey('nav_statistiques')}
                </a>
              </li>
              <li
                className="nav-item"
                id="payments"
              >
                <a
                  className="nav-link scroll"
                  href="#payment_Method"
                >
                 {findElementByKey('payments')}
                </a>
              </li>
              <li
                className="nav-item"
                id="aboutUs"
              >
                <a
                  className="nav-link scroll"
                  href="#nav_a_propos"
                >
                  {findElementByKey('nav_a_propos')}
                </a>
              </li>
              <li
                className="nav-item"
                id="contact"
              >
                <a
                  className="nav-link scroll"
                  href="#nav_contact"
                >
                  {findElementByKey('nav_contact')}
                </a>
              </li>
              <li className='nav-item mt-2  d-lg-none d-md-none'>
                <Link className="btn  rounded btn-light btn-gen  text-dark " to="/services">
                  {findElementByKey('nav_reserver')}
                </Link>
              </li>
            </ul>
          </div>
          <div className="navbar-other ms-lg-4">
            <ul
              className="navbar-nav flex-row align-items-center ms-auto"
              data-sm-skip="true"
            >
              <li className="nav-item">
                <button
                  onClick={() => setLanguage()}
                  className="nav-link border-0 bg-transparent"
                >
                  {currentLanguage === "EN" ? "FR" : "EN"}
                </button>
              </li>
              <li className="nav-item btn-gen d-none d-md-block">
                <Link
                  to="/services"
                  className="btn btn-sm btn-dark rounded"
                >
                  {findElementByKey('nav_reserver')}
                </Link>
              </li>
              <li className="nav-item d-lg-none">
                <div className="navbar-hamburger">
                  <button
                    className="hamburger animate plain"
                    id='hamburger'
                    onClick={toggle}
                    data-toggle="offcanvas-nav"
                  >
                    <span></span>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default NavBar