import React from "react";
import Layout from '../components/layout2'
import { HeadFC } from 'gatsby'
import { DataContextProvider } from '../context/DataContext'


const IndexPage = () => {
  return <>
      <DataContextProvider>
        <Layout>
          <></>
        </Layout>
      </DataContextProvider>
  </>
}
export const Head: HeadFC = () => <title>BlackWalker</title>

export default IndexPage;
