import React from 'react'
import { useThemeContext } from '../../context/DataContext'


export default function PaymentMethod() {
  const { findElementByKey } = useThemeContext()
  return (
    <section
      className="wrapper bg-light"
      id="payment_Method"
    >
      <div className="container py-2 py-md-16" id='container-payment'>
        <div className="row gy-10 gy-sm-13 gx-md-8 gx-xl-12 align-items-center">
          <div className="col-lg-6" style={{padding: '0px', marginTop: '30px'}}>
            <div className="row gx-md-5 gy-5">
              <div className="col-12">
                <figure>
                  <img
                    src="/img/paymentImage.png"
                    srcSet="/img/paymentImage.svg"
                    alt=""
                    loading="lazy"
                  />
                </figure>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <h2 className="fs-16 text-uppercase  text-dark mb-3  mb-3 titleBlack">
              {findElementByKey('fast_secured_payments')}
            </h2>
            <h3 className="display-3 mb-10 ">
              {findElementByKey('reliable_payments')}
            </h3>
            <div className="row gy-8">
                <p className='justify'>
                    {findElementByKey('payment_text')}
                </p>
                <ul className="list-group w-100">
                    <div className="list-group-item d-flex">
                        <span className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1" style={{backgroundColor: '#070909', width: '40px', height: '40px'}}>
                        <figure className="rounded">
                            <img
                                src="/img/Orange.png"
                                srcSet="/img/Orange.png"
                                alt=""
                                loading="lazy"
                            />
                        </figure>
                        </span>
                        <div className='d-flex payment'>
                            <span className='text-sub'>{findElementByKey('Commercial_number')}</span>
                            <span className='text-sup d-inline'>{findElementByKey('phone_com')}</span>
                        </div>
                    </div>
                    <div className="list-group-item d-flex">
                        <div className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 " style={{backgroundColor: '#070909', width: '40px', height: '40px'}}>
                            <figure className="rounded">
                                <img 
                                    src="/img/Orange.png"
                                    srcSet="/img/Orange.png"
                                    alt=""
                                    loading="lazy"
                                />
                            </figure>
                        </div>
                        <div className='d-flex payment'>
                            <span className='text-sub'>{findElementByKey('Commercial_code')}</span>
                            <span className='text-sup d-inline'>{findElementByKey('code_com')}</span>
                        </div>
                    </div>
                    <div className="list-group-item d-flex">
                        <div className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 " style={{backgroundColor: '#070909', width: '40px', height: '40px'}}>
                            <figure className="rounded">
                                <img
                                    src="/img/Orange.png"
                                    srcSet="/img/Orange.png"
                                    alt=""
                                    loading="lazy"
                                />
                            </figure>
                        </div>
                        <div className='d-flex payment'>
                            <span className='text-sub'>{findElementByKey('om_number')}</span>
                            <span className='text-sup d-inline'>{findElementByKey('om_phone')}</span>
                        </div>
                    </div>
                    <div className="list-group-item d-flex">
                        <div className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 " style={{backgroundColor: '#070909', width: '40px', height: '40px'}}>
                            <figure className="rounded">
                                <img 
                                    src="/img/visa.png"
                                    srcSet="/img/visa.png"
                                    alt=""
                                    loading="lazy"
                                />
                            </figure>
                        </div>
                        <div className='d-flex payment justify-content-center'>
                            <span className='text-sub'>{findElementByKey('Visa_card_payments')}</span>
                        </div>
                    </div>
                    <div className="list-group-item d-flex">
                        <div className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 " style={{backgroundColor: '#070909', width: '40px', height: '40px'}}>
                            <figure className="rounded">
                                <img 
                                    src="/img/master.png"
                                    srcSet="/img/master.png"
                                    alt=""
                                    loading="lazy"
                                />
                            </figure>
                        </div>
                        <div className='d-flex payment justify-content-center'>
                            <span className='text-sub'>{findElementByKey('masterCard_payments')}</span>
                        </div>
                    </div>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
