import React, { useEffect, useRef, useState } from 'react'
import { useThemeContext } from '../../context/DataContext'
import { graphql, useStaticQuery } from 'gatsby'
import Carousel from 'react-multi-carousel'
import { responsive } from './home.utils'



const queryAsset = graphql`
  query images {
    allStrapiBwImage {
      nodes {
        model
        price
        id
        color
        brand
        localizations {
          data {
            attributes {
              color
            }
          }
        }
        car_image {
            url
        }
        key
      }
    }
  }
`
interface car {
  id: string
  color: string
  price: number
  model: string
  brand: string
  localizations: {
    data: [
      {
        attributes: {
          color: string
        }
      }
    ]
  }
  car_image: {

    url: string

  }
  key: string
}

export default function EstimationCar() {

  const staticQuery = useStaticQuery(queryAsset)
  const cars = staticQuery.allStrapiBwImage.nodes
  const { findElementByKey } = useThemeContext()
  const [selectedCar, setSelectedCar] = useState(cars[0].key)
  const [totalPrice, setTotalPrice] = useState<number>(1)
  const [numberDays, setNumberDays] = useState<number>(1)
  const [locationReservation, setLocationReservation] = useState('enVille')
  const [dayTown, setDayTown] = useState<number>(1)
  const [dayOutTown, setDayOutTown] = useState<number>(1)
  const [slideIndex, setSlideIndex] = useState<number>(1)
  const [carousel, setCarousel] = useState<Carousel | null>(null)
  let slides: string | any[], dots: string | any[]
  if (typeof window !== 'undefined') {
    slides = document.getElementsByClassName(
      'mySlides'
    ) as unknown as HTMLElement[]
    dots = document.getElementsByClassName('dot') as unknown as HTMLElement[]
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocationReservation(event.target.value)
    if (numberDays > 2) {
      setDayOutTown(numberDays - 1)
      setDayTown(1)
    } else {
      setDayOutTown(1)
      setDayTown(1)
    }
    setNumberDays(numberDays)
  }

  const resetPrice = () => {
    cars.map((car: car, i: number) => {
      if (locationReservation === 'enVille') {
        if (selectedCar === car.key) setTotalPrice(numberDays * car.price)
      } else if (locationReservation === 'horsVille') {
        if (selectedCar === car.key)
          setTotalPrice((numberDays * car.price) + (numberDays * 10000))
      } else {
        if (selectedCar === car.key)
          setTotalPrice((car.price * dayTown) + (car.price * dayOutTown) + 10000)
      }
    })
  }

  const updateSlideIndex = (n: number) => {
    if (n > slides.length) {
      setSlideIndex(1);
    } else if (n < 1) {
      setSlideIndex(slides.length);
    } else {
      setSlideIndex(n);
    }
  }  

  const changeSlideSelect = (key: string) => {
    cars.map((car: car, i: number) => {
      if (car.key === key) {
        updateSlideIndex(i + 1);
      }
    });
  }

  const calculateTotalPrice = (key: string) => {
    cars.map((car: car, i: number) => {
      if (car.key === key) {
        let carPrice = car.price
        if (locationReservation === 'enVille') {
          carPrice = carPrice * numberDays
          setTotalPrice(carPrice)
        } else if (locationReservation === 'horsVille') {
          setTotalPrice(carPrice * numberDays + 10000 * numberDays)
        } else {
          carPrice =
            (carPrice * (dayTown || 0)) + (carPrice * (dayOutTown || 0)) + (10000 * (dayOutTown || 0))
          setTotalPrice(carPrice)
        }
      }
    })
  }

  const handleCarouselChange = (index: number) => {
    setSelectedCar(cars[index].key);
  }

  useEffect(() => {
    changeSlideSelect(selectedCar)
    calculateTotalPrice(selectedCar)
  }, [selectedCar, numberDays, dayOutTown, dayTown])
  
  useEffect(() => {
    cars.map((car: car, i: number) => {
      if (slideIndex === i + 1) {
        setSelectedCar(car.key);
      }
    });
  }, [slideIndex]);
 
  useEffect(() => {
    resetPrice()
  }, [locationReservation])

  useEffect(() => {
    setNumberDays(dayOutTown + dayTown)
  }, [dayOutTown, dayTown])

  useEffect(() => {
    setNumberDays(1)
  }, [])

  useEffect(() => {
    let msg: Element | any = document.querySelector('#msg');
    let err: Element | any = document.querySelector('#error');
    let textErr: Element | any = document.querySelector('#err');
    let modal: Element | any = document.getElementById("myModal");
    let span: Element | any = document.getElementsByClassName("close")[0];
    span.onclick = function () {
      modal.style.display = "none";
      setNumberDays(30)
      setDayOutTown(15)
      setDayTown(15)
    }
    window.onclick = function (event) {
      if (event.target == modal) {
        modal.style.display = "none";
        setNumberDays(30)
        setDayOutTown(15)
        setDayTown(15)
      }
    }

    if (numberDays > 30 || (dayOutTown + dayTown > 30) || dayOutTown > 30 || dayTown > 30) {
      err.style.display = 'block';
      msg.style.display = 'none';
      textErr.style.color = '#e0636d';
      modal.style.display = "block";
    } else {
      err.style.display = 'none';
      msg.style.display = 'block';
      textErr.style.color = 'white';
      modal.style.display = "none";
    }
  }, [numberDays, dayOutTown, dayTown])

  useEffect(() => {
    const index =  cars.findIndex((c: {[key: string]: string}) => c.key === selectedCar);
    carousel?.goToSlide(index)
  }, [selectedCar])

  return (
    <section className="wrapper bg-dark bg-content text-black d-flex section">
      <div
        className="container d-flex justify-content-center flex-column my-6"
        style={{ zIndex: 5, position: 'relative' }}
        id="estimation"
      >
        <div
          id="nav_estimation"
          className="d-flex row align-items-center justify-content-center "
        >
          <h1 className="title text-center text-uppercase">
            {findElementByKey('estimation_cout_titre')}
          </h1>
          <div
            className="col-10 col-md-8 col-lg-5 flex-grow-1 content estimation text-lg-start"
            data-group="page-title"
          >
            <div className="d-flex col-12 justify-content-center">
              <form id='form' action="/">
                <div className="form-group">
                  <label
                    htmlFor="feedback-user"
                    className="form-label"
                  >
                    {findElementByKey('label_choix_vehicule')}
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setSelectedCar(e.target.value)
                    }}
                    value={selectedCar}
                  >
                    {cars.map((car: car, i: number) => {
                      return (
                        <option
                          key={i}
                          value={car.key}
                        >
                          {car.brand} {car.model}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className="">
                  <label
                    htmlFor="feedback-user"
                    className="form-label d-block"
                  >
                    {findElementByKey('label_booking_options')}
                  </label>
                  <div className="d-flex flex-wrap">
                    <div
                      className="form-check form-check-inline"
                      style={{ textAlign: 'left' }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        id="flexRadioDefault1"
                        checked={locationReservation === 'enVille'}
                        value="enVille"
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label radio_btn-text-color"
                        htmlFor="flexRadioDefault1"
                      >
                        {findElementByKey('label_en_ville')}
                      </label>
                    </div>
                    <div
                      className="form-check form-check-inline"
                      style={{ textAlign: 'left' }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        id="flexRadioDefault2"
                        onChange={handleChange}
                        value="horsVille"
                        checked={locationReservation === 'horsVille'}
                      />
                      <label
                        className="form-check-label radio_btn-text-color"
                        htmlFor="flexRadioDefault2"
                      >
                        {findElementByKey('label_hors_ville')}
                      </label>
                    </div>
                    <div
                      className="form-check form-check-inline"
                      style={{ textAlign: 'left' }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        id="flexRadioDefault2"
                        onChange={handleChange}
                        value="lesDeux"
                        checked={locationReservation === 'lesDeux'}
                      />
                      <label
                        className="form-check-label radio_btn-text-color"
                        htmlFor="flexRadioDefault2"
                      >
                        {findElementByKey('label_les_deux')}
                      </label>
                    </div>
                  </div>
                </div>
                {locationReservation !== 'lesDeux' ? (
                  <div className="form-group">
                    <label
                      htmlFor="feedback-user"
                      className="form-label"
                    >
                      {findElementByKey('label_nombre_jour')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      value={numberDays || ""}
                      maxLength={2}
                      placeholder={`${findElementByKey('label_nombre_jour')}`}
                      name="email"
                      min={1}
                      max={30}
                      pattern={'[0-9]*'}
                      inputMode='numeric'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setNumberDays(parseInt(e.target.value))
                      }}
                      disabled={locationReservation === 'lesDeux'}
                    />
                  </div>
                ) : (
                  <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div className="form-group col-12 col-md-6 col-lg-5">
                      <label
                        htmlFor="feedback-user"
                        className="form-label"
                      >
                        {findElementByKey('label_nombre_jour_en_ville')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        value={dayTown || ""}
                        maxLength={2}
                        placeholder={`${findElementByKey(
                          'label_nombre_jour_en_ville'
                        )}`}
                        pattern={'[0-9]*'}
                        inputMode='numeric'
                        name="email"
                        min={1}
                        max={29}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setDayTown(parseInt(e.target.value))
                        }}
                        disabled={locationReservation !== 'lesDeux'}
                      />
                    </div>
                    <div className="form-group col-12 col-md-6 col-lg-5">
                      <label
                        htmlFor="feedback-user"
                        className="form-label"
                      >
                        {findElementByKey('label_nombre_jour_hors_ville')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        value={dayOutTown || ""}
                        maxLength={2}
                        placeholder={`${findElementByKey(
                          'label_nombre_jour_hors_ville'
                        )}`}
                        pattern={'[0-9]*'}
                        inputMode='numeric'
                        name="email"
                        min={1}
                        max={29}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setDayOutTown(parseInt(e.target.value))
                        }}
                        disabled={locationReservation !== 'lesDeux'}
                      />
                    </div>
                  </div>
                )}
                <p
                  style={{
                    fontSize: '0.75rem',
                    lineHeight: '1rem',
                    minHeight: '40px',
                  }}
                  className="msg_add_price_outTown"
                  id='msg'
                >
                  {findElementByKey('msg_add_price_outTown')}
                </p>
                <p className='text-danger' id='error'>{findElementByKey('res_error_text')}</p>
                <div id="myModal" className="modal">
                  <div className="modal-content">
                    <div className="modal-header">
                      <span className="close">&times;</span>
                    </div>
                    <div className="modal-body">
                      <p className='pop'>
                        {findElementByKey('popup_text')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-4 align-items-center flex-column justify-content-between">
                  <div
                    style={{ color: 'white', width: '100%' }}
                    className="d-flex justify-content-between"
                  >
                    <div id='err'>
                      {findElementByKey('texte_total_estimation')}
                      &nbsp;
                      <span>
                        {locationReservation === 'lesDeux'
                          ? (dayTown || 0) + (dayOutTown || 0)
                          : numberDays || 0}
                      </span>
                      &nbsp;
                      {findElementByKey('texte_total_estimation_suite')}
                    </div>
                    <div>
                      <span
                        style={{
                          fontSize: '14px',
                        }}
                      >
                        {findElementByKey('monnaie_value')}
                      </span>{' '}
                      {totalPrice || 0}
                    </div>
                  </div>
                  <div
                    className="mt-4 d-none d-lg-block"
                    style={{ width: '100%' }}
                  >
                    <li className="nav-item mt-4 btn-nav_reserver list-unstyled mt-lg-0">
                      <a
                        href="https://www.blackwalker.com/book-a-car/"
                        className="btn btn-md btn-light rounded"
                        style={{ color: '#343f52', width: '100%' }}
                      >
                        {findElementByKey('nav_reserver')}
                      </a>
                    </li>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            className="col-md-12 col-sm-12 col-lg-5"
            id='card-container'
            style={{ marginTop: '45px' }}
          >
            <Carousel
              beforeChange={handleCarouselChange}
              responsive={responsive}
              arrows={true}
              ref={(el) => setCarousel(el)}
            >
              {cars
                .map((car: any, id: number) => {
                  return (
                    <div key={id} className="card" style={id === 0 ? { marginRight: '4px' } : {}}>
                      <div className='h-50 w-100'>
                        <img
                          className="rounded d-block w-100"
                          style={{objectFit: 'contain', height: '300px'}}
                          src={car.car_image.url}
                          alt="Card image cap"
                        />
                      </div>
                      <div className="card-body">
                        <img
                          src={'/img/toyota.png'}
                          className="icon-svg icon-svg-xs solid-duo text-grape-fuchsia mt-1 me-3"
                          style={{ width: '2rem', height: '2rem' }}
                        />
                        <p className="card-title">{car.brand}</p>
                        <h5 className="card-text">{car.model}</h5>
                        <div style={{ fontSize: 'small' }}>
                          <span style={{ fontWeight: 'bold' }}>
                            {findElementByKey('monnaie_value')}{' '}
                            {Math.round(Math.abs(car.price) / 100) / 10}k{' '}
                          </span>
                          {findElementByKey('per_day')}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Carousel>
            <div
              className="mt-4 d-block d-lg-none"
              style={{ width: '100%' }}
            >
              <li className="nav-item mt-4 btn-nav_reserver list-unstyled mt-lg-0">
                <a
                  href="https://www.blackwalker.com/book-a-car/"
                  className="btn btn-md btn-light rounded nav-link"
                  style={{ color: '#343f52', width: '100%' }}
                >
                  {findElementByKey('nav_reserver')}
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
