import React from 'react'
import { useThemeContext } from '../../context/DataContext'

export default function Footer() {
  const { findElementByKey, currentLanguage, setLanguage } = useThemeContext()
  return (
    <footer className="bg-dark text-white " >
      <div className="container py-13 py-md-15">
        <div className="row gy-6 gy-lg-0">

          <div className="col-md-3 col-lg-3">
            <a href="#nav_accueil">
              <img className='img-fluid' src="/img/LogoWhite.png" alt="logo" loading="lazy" />

            </a>
          </div>

          <div className="col-md-3 col-lg-3">
            <div className="widget">
              <h4 className="widget-title text-white  mb-3">
                {findElementByKey('learn_more_footer')}
              </h4>
              <ul className="list-unstyled  mb-0">
                <li>
                  <a
                    className="link scroll"
                    href="#nav_estimation"
                  >
                    {findElementByKey('estimation')}
                  </a>
                </li>
                <li>
                  <a
                    className="link scroll"
                    href="#nav_service"
                  >
                    {findElementByKey('nos_service_footer')}
                  </a>
                </li>
                <li>
                  <a
                    className="link scroll"
                    href="#nav_statistiques"
                  >
                    {findElementByKey('statistique')}
                  </a>
                </li>
                <li>
                  <a
                    className="link scroll"
                    href="#payment_Method"
                  >
                    {findElementByKey('paiement')}
                  </a>
                </li>
                <li>
                  <a
                    className="link scroll"
                    href="#nav_a_propos"
                  >
                    {findElementByKey('a_propos_footer')}
                  </a>
                </li>
                <li>
                  <a
                    className="link scroll"
                    href="#nav_contact"
                  >
                    {findElementByKey('contact_us_footer')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-lg-3">
            <div className="widget">
              <h4 className="widget-title text-white  mb-3">
                {findElementByKey('contact_us_footer')}
              </h4>
              <div className="d-flex flex-row align-items-center">
                <img
                  src="/img/svg/emails1.svg"
                  className="icon-svg icon-svg-xs solid-duo text-grape-fuchsia mt-1 me-4"
                  style={{ width: '1rem', height: '1rem' }}
                  alt=""
                  loading="lazy"
                />
                <a
                  href="mailto:sales@blackwalker.com"
                  className="link-body text-white"
                >
                  {findElementByKey('email_value')}
                </a>
              </div>
              <div className="d-flex flex-row align-items 
              "  >
                <img
                  src="/img/svg/rotary1.svg"
                  className="icon-svg icon-svg-xs solid-duo text-grape-fuchsia  me-4"
                  style={{ width: '1rem', height: '1rem' }}
                  alt=""
                  loading="lazy"
                />
                <a
                  className="text-white"
                  href="tel:+237 698819611"
                >
                  {findElementByKey('telephone_value')}
                </a>
              </div>
            </div>

          </div>

          <div className=" col-md-3 col-lg-3 mapouter">
            <h4>{findElementByKey('maps_value')} </h4>
            <div className="gmap_canvas"><iframe width="600" height="500" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3980.655652228513!2d11.49208271456032!3d3.883785149280675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x108bcf16dac38685%3A0x11f378ee4dd04aea!2sTotalEnergies%20NKOMKANA%201!5e0!3m2!1sfr!2scm!4v1673004792410!5m2!1sfr!2scm" scrolling="no"  ></iframe>
            </div>
            <div className=" col-md-12  d-flex mt-1 ">
              <nav className="nav social flex-column flex-md-row d-flex  ">

                <div className="d-flex  mx-md-2">
                  <img
                    src="/img/svg/location-dot-solid.svg"
                    className="icon-svg icon-svg-xs solid-duo text-grape-fuchsia mt-1 me-2"
                    style={{ width: '0.9rem', height: '0.9rem' }}
                    alt=""
                    loading="lazy"
                  />
                  <a className="maps" href='https://goo.gl/maps/KNL9o9ko3m6WZg1u9' style={{ fontSize: '15px' }}>{findElementByKey('addresse_value')}</a>

                </div>
              </nav>

            </div>
          </div>


        </div>

        <div
          className="row gy-6 gy-lg-0 align-items-end"
          style={{ marginTop: '0px' }}
        >
          <div className="col-md-5 col-lg-4 col-xl-3">
            <nav className="nav social ">
              <a
                href="https://www.facebook.com/blkwlkr"
                target="_blank"
              >
                <img
                  src="/img/svg/square-facebook-brands.svg"
                  className="icon-svg icon-svg-xs solid-duo text-grape-fuchsia mt-1 me-3"
                  style={{ width: '1rem', height: '1rem' }}
                  alt=""
                  loading="lazy"
                />
              </a>
              <a
                href="https://www.instagram.com/blackwalker237/"
                target="_blank"
              >
                <img
                  src="/img/svg/square-instagram-brands.svg"
                  className="icon-svg icon-svg-xs solid-duo text-grape-fuchsia mt-1 me-3"
                  style={{ width: '1rem', height: '1rem' }}
                  alt=""
                />
              </a>
            </nav>
          </div>
        </div>
        <br></br>


        <div
          className="row gy-8 gy-lg-0"
        >
          <div className="">
            <p style={{ fontSize: '12px', fontWeight: '300' }}>
              {findElementByKey('copyright_footer')}&nbsp;
              {findElementByKey('text_copyright')}
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}