import React from 'react'
import About from './AboutUs'
import Contact from './ContactUs'
import EstimationCar from './CostEstimation'
import Home from './Header'
import Service from './Services'
import Statistique from './Statistics'
import PaymentMethod from './PaymentsMethods'

export default function Components() {
  return (
    <div>
      <Home />
      <EstimationCar />
      <Service/>
      <Statistique/>
      <PaymentMethod />
      <About/>
      <Contact />
      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </div>
  )
}
