import React, {
  createContext,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'

interface data {
  key: string
  id: string
  localizations: {
    EN: {
      value: string
    }
    FR: {
      value: string
    }
  }
}
interface IThemeContext {
  data?: data[]
  setValue: (items: data[]) => void
  findElementByKey: (key: string) => string | null
  currentLanguage: string
  setLanguage: () => void
}

export const DataContext = createContext<IThemeContext>({
  setValue: () => null,
  data: [],
  findElementByKey: (key: string) => '',
  currentLanguage: 'EN',
  setLanguage: () => null,
})

interface Props {
  children: JSX.Element
}

export const DataContextProvider: React.FC<Props> = ({ children }) => {

  const [data, setData] = useState<Array<data>>([])
  let lang: string
  if (typeof window !== `undefined`) {
    lang =
      localStorage.getItem('lang')?.toUpperCase() ||
      navigator.language.slice(0, 2).toUpperCase()
  } else {
    lang = 'FR'
  }
  const [currentLanguage, UpdateLanguage] = useState<string>(lang)
  useEffect(() => {
    let defaultLanguage = localStorage.getItem('defaultLanguage')
    if (!defaultLanguage) {
      const navigatorLanguage = navigator.language.slice(0, 2).toUpperCase()
      localStorage.setItem('defaultLanguage', navigatorLanguage)
      defaultLanguage = navigatorLanguage
    }
    UpdateLanguage(defaultLanguage)
  }, [])
  const setValue = (items: data[]) => {
    setData(items)
  }
  const setLanguage = () => {
    if (currentLanguage === 'EN') {
      UpdateLanguage('FR')
      localStorage.setItem('defaultLanguage', 'FR')
    } else {
      UpdateLanguage('EN')
      localStorage.setItem('defaultLanguage', 'EN')
    }
  }
  const findElementByKey = (key: string) => {
    const value = data.find((item: data) => {
      return item.key === key
    })
    if (!value) return null;
    if (currentLanguage === 'EN') {
      return value?.localizations['FR'].value
    } else {
      return value?.localizations['EN'].value
    }

  }

  return (
    <DataContext.Provider
      value={{
        data,
        setValue,
        currentLanguage,
        setLanguage,
        findElementByKey,
      }}
    >
      <>{children}</>
    </DataContext.Provider>
  )
}

export const useThemeContext = () => useContext(DataContext)