import React, { useState } from 'react'
import { useThemeContext } from '../../context/DataContext'

export default function Service() {
  const { findElementByKey } = useThemeContext()
  const [showText1, setShowText1] = useState<boolean>(false)
  const [showText3, setShowText3] = useState<boolean>(false)
  return (
    <section
      className="wrapper bg-light section"
      id="nav_service"
    >
      <div className="container pt-12">
        <div className="row text-center">
          <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <h2 className="fs-16 text-uppercase  mb-3 titleBlack">
              {findElementByKey('title_service')}
            </h2>
            <h3 className="display-3 mb-9 px-xl-11">
              {findElementByKey('sous_titre_service')}
            </h3>
          </div>
        </div>
        <div className="row gy-8 mb-17 service">
          <div className="col-lg-4">
            <div className="d-flex flex-row">
              <div>
                <img
                  src="/img/svg/car-front.svg"
                  className="icon-svg icon-svg-sm solid-duo text-grape-fuchsia me-4"
                  alt=""
                  loading="lazy"
                />
              </div>
              <div>
                <h3 className="fs-22 mb-1">
                  {findElementByKey('titre_location_vehicule')}
                </h3>
                <p style={{ textAlign: 'justify' }}>
                  {process.env.APP_NAME}
                  {findElementByKey('description_location_car')}
                </p>
                {showText1 ? (
                  <p style={{ textAlign: 'justify' }}>
                    {findElementByKey('description_location_car_more')}
                  </p>
                ) : (
                  ''
                )}
                <button
                  className="more Hover showBtn"
                  onClick={() => setShowText1(!showText1)}
                >
                  {showText1
                    ? findElementByKey('show_less')
                    : findElementByKey('learn_more_footer')}
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <figure>
              <img
                src="/img/services.jpg"
                srcSet="/img/services.jpg"
                alt=""
                loading='lazy'
              />
            </figure>
          </div>
          <div className="col-lg-4">
            <div className="d-flex flex-row">
              <div>
                <img
                  src="/img/svg/house-heart-fill.svg"
                  className="icon-svg icon-svg-sm solid-duo text-grape-fuchsia me-4"
                  alt=""
                />
              </div>
              <div>
                <h3 className="fs-22 mb-1">
                  {findElementByKey('titre_location_appartement')}
                </h3>
                <p style={{ textAlign: 'justify' }}>
                  {process.env.APP_NAME}
                  {findElementByKey('description_location_appartment')}{' '}
                </p>
                {showText3 ? (
                  <p style={{ textAlign: 'justify' }}>
                    {findElementByKey('description_location_appartment_more')}
                  </p>
                ) : (
                  ''
                )}
                <button
                  className="more Hover showBtn"
                  onClick={() => setShowText3(!showText3)}
                >
                  {showText3
                    ? findElementByKey('show_less')
                    : findElementByKey('learn_more_footer')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
