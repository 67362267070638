import React, { ReactElement, useContext, useEffect, useState } from 'react'
import NavBar from '../homePage/Navbar'
import Footer from '../homePage/Footer'
import '../../../static/css/plugins.css'
import '../../../static/css/style.css'
import '../../../static/css/colors/aqua.css'
import '../../../static/css/fonts/thicccboi.css'
import '../../style/style.css'
import { Script } from 'gatsby'
import Components from '../homePage/Components'
import { graphql, useStaticQuery } from 'gatsby'
import formatData from '../../utils/format'
import { DataContext } from '../../context/DataContext'
import Loader from '../Loader'
import { User, UserContext } from "../../context/AuthContext"
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth"
import 'react-multi-carousel/lib/styles.css'
import { auth } from "../../config/firebase"

const TAG_ID = 'G-5RWC7G019X'

const queryAsset = graphql`
  query assets {
    allStrapiBwAsset {
      nodes {
        key
        id
        localizations {
          data {
            id
            attributes {
              value
              key
            }
          }
        }
        value {
          data {
            value
          }
        }
      }
    }
  }
`

const Layout = ({ children }: { children: ReactElement }) => {
  if (typeof window !== 'undefined') {
    document.body.setAttribute('data-bs-spy', 'scroll')
  }
  const [loading, setLoading] = useState<boolean>(true);
  
  const [jqueyLoaded, setJqueryLoaded] = useState(false)
  const [pluginLoaded, setPluginLoaded] = useState(false)
  const [isAuth, setAuth] = useState<boolean>(false);
  const [user, setUser] = useState<User>({
    displayName: "",
    email: "",
    phoneNumber: "",
    emailVerified: null,
    uid: null,
  });

  
  const staticQuery = useStaticQuery(queryAsset)
  const assets = staticQuery.allStrapiBwAsset.nodes

  const { setValue } = useContext(DataContext)

  useEffect(() => {
    setValue(formatData(assets))
    function checkAuthUser() {
      setLoading(true);
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const { displayName, email, emailVerified, phoneNumber, uid } = user;
          setAuth(false);
          setUser({ displayName, email, emailVerified, phoneNumber, uid });
        } else {
          setAuth(false);
        }
        setLoading(false);
      });
    }
    checkAuthUser();
    return () => { };
  }, [])



  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${TAG_ID}`}
        strategy="off-main-thread"
      />
      <Script id="gtag-config" strategy="off-main-thread" forward={[`gtag`]}>
        {`
    window.dataLayer = window.dataLayer || []
    window.gtag = function gtag() { window.dataLayer.push(arguments) }
    gtag('js', new Date())
    gtag('config', '${TAG_ID}')
  `}
      </Script>
      <Script
        src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
        integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
        crossOrigin="anonymous"
        onLoad={() => setJqueryLoaded(true)}
      ></Script>
      {jqueyLoaded && (
        <>
          <Script
            src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
            integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
            crossOrigin="anonymous"
          ></Script>
          <Script
            src="/plugins.js"
            onLoad={() => setPluginLoaded(true)}
          />

          {pluginLoaded && <Script src="/theme.js" />}


        </>
      )}
      {!loading ?
        <>
          <NavBar />
          <Components />
          <>{children}</>
          <Footer />
        </> : <Loader />}
    </>
  )
}

export default Layout
