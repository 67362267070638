import React, { useEffect, useState } from 'react'
import { useThemeContext } from '../../context/DataContext'

type WND = Window & typeof globalThis & { TyperSetup: Function }

export default function Home() {
  const { findElementByKey, currentLanguage } = useThemeContext()

  useEffect(() => {
    const TyperSetup = (window as WND).TyperSetup
    if (TyperSetup) TyperSetup()
  }, [currentLanguage])
    

  return (
    <section
      className="wrapper bg-light"
      id="nav_accueil"
    >
      <div className=" d-flex flex-column flex-lg-row pt-5 pb-15 py-lg-12 py-xl-14  align-items-center" id='hero-container'>
        <div
          className="col-md-7"
          id='container-img'
          style={{ marginTop: '64px' }}
        >
            {
              <img
                id='img'
                src={currentLanguage === 'EN' ? '/img/imagesEnglish.png' : '/img/imagesFrench.png'}
                alt=""
                style={{width: '100%'}}
                loading='lazy'
             />
            }
        </div>
        <div
          className="text-center text-lg-start row col-12 col-lg-5 mt-4 mt-lg-4"
          id='container-description'
          data-group="page-title"
        >
          <h1 className="display-2 mb-4 mx-sm-n2 mx-md-0 px-lg-0 text-dark">
            {findElementByKey('header_title')} &nbsp;
            <p>
              <span
                key={currentLanguage}
                className="typer text-secondary text-nowrap"
                data-delay="100"
                data-words={[
                  findElementByKey('header_typer1'),
                  findElementByKey('header_typer2'),
                  findElementByKey('header_typer3'),
                ]}
              >
                fast tr
              </span>
              <span
                className="cursor text-secondary"
                data-owner="typer"
                style={{ transition: 'all 0.1s ease 0s', opacity: '1' }}
              >
                |
              </span>
            </p>
          </h1>
          <p className="lead mb-7 px-md-10 px-lg-0 text-dark">
            {findElementByKey('header_description')}
          </p>
        </div>
      </div>
    </section>
  )
}
